import './Footer.css';

const Footer = () => (
  <footer>
    <a href='https://github.com/jameszhang-a/portfolio' className='link'>
      James Zhang
    </a>
  </footer>
);

export default Footer;
