const header = {
  // all the properties are optional - can be left empty or deleted
  homepage : 'https://jameszhanga.com',
  title    : 'JZ'
};

const about = {
  // all the properties are optional - can be left empty or deleted
  name        : 'James Zhang',
  role        : 'Software Developer',
  description :
    "👋🏼 I'm a Senior at the University of Wisconsin-Madison double majoring in Computer Science and Mchanical Engineering",
  resume      : 'https://example.com',
  social      : {
    linkedin : 'https://linkedin.com/in/jameszhanga',
    github   : 'https://github.com/jameszhang-a'
  }
};

const projects = [
  // Add or edit projects
  // {
  //   name        : 'Project 1',
  //   description :
  //     'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
  //   stack       : [ 'SASS', 'TypeScript', 'React' ],
  //   sourceCode  : 'https://github.com',
  //   livePreview : 'https://github.com'
  // },
  // {
  //   name        : 'Project 2',
  //   description :
  //     'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
  //   stack       : [ 'SASS', 'TypeScript', 'React' ],
  //   sourceCode  : 'https://github.com',
  //   livePreview : 'https://github.com'
  // },
  // {
  //   name        : 'Project 3',
  //   description :
  //     'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
  //   stack       : [ 'SASS', 'TypeScript', 'React' ],
  //   sourceCode  : 'https://github.com',
  //   livePreview : 'https://github.com'
  // }
];

const skills = [
  // Add and edit skills
  'JavaScript',
  'Python',
  'Java',
  'C/C++',
  'React',
  'Redux',
  'Git'
];

const contact = {
  // email is optional - if left empty Contact section won't show up
  email : 'jameszhanga@gmail.com'
};

export { header, about, projects, skills, contact };
